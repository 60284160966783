.loader_conta {
  display: flex;
  justify-content: center;
  align-items: center;
  /* opacity: 0.5; */
  width: auto;
  height: 100vh;
  background-color: rgb(12, 60, 96);
  /* background-color: rgb(229, 229, 229); */
  flex-direction: column;
}
@keyframes circular-motion {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader_img {
  animation: circular-motion 3s linear infinite;
  margin-bottom: 20px;
}
