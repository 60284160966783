.app-style {
  background-color: #ffffff;
}
.navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
